<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Toon</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>items</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Zoeken op naam..."
              />
<!--              <b-button-->
<!--                variant="primary"-->
<!--                @click="goToAddRelationsView()"-->
<!--              >-->
<!--                <span class="text-nowrap">Relatie Toevoegen</span>-->
<!--              </b-button>-->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refRelationsListTable"
        class="position-relative"
        :items="fetchRelations"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
            :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
            class="font-weight-bold"
          >
            #{{ data.value }}
          </b-link>
        </template>

        <!-- Column: Relations -->
        <template #cell(firstName)="data">
          <b-media
            vertical-align="center"
          >
            <template #aside>
              <a href="/" class="b-avatar router-link-active badge-secondary rounded-circle" target="_self" style="width: 32px; height: 32px;">
                <span v-if="data.item.type === 1 && data.item.gender === 'f'" class="b-avatar-text" style="font-size: 20px">
                  <span style="font-size: 20px" class="fas fa-female"></span>
                </span>
                <span v-if="data.item.type === 1 && data.item.gender === 'm'" class="b-avatar-text" style="font-size: 20px">
                  <span style="font-size: 20px" class="fas fa-male"></span>
                </span>
                <span v-if="data.item.type === 1 && (!data.item.gender || data.item.gender === '')" class="b-avatar-text" style="font-size: 20px">
                  <span style="font-size: 20px" class="fas fa-restroom"></span>
                </span>
                <span v-if="data.item.type === 2" class="b-avatar-text" style="font-size: 20px">
                  <span style="font-size: 20px" class="fas fa-building"></span>
                </span>
              </a>
            </template>
            <b-link
              :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.type === 1 ? getFullName(data.item) : data.item.companyName }}
            </b-link>
            <div class="text-muted">
              {{ data.item.email }}
            </div>
          </b-media>
        </template>

        <template #cell(type)="data">
          <span class="text-nowrap">
            {{ resolveTypeVariant(data.value) }}
          </span>
        </template>

        <template #cell(street)="data">
          <div vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              <small>{{ data.item.street }} {{ data.item.houseNumber }}{{ data.item.houseNumberAddition?data.item.houseNumberAddition:'' }}</small>
            </span>
            <span class="font-weight-bold d-block text-nowrap">
              <small>{{ data.item.postalCode }}, {{ data.item.city }}</small>
            </span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <b-link
              :to="{ name: 'apps-relations-edit', params: { id: data.item.id }}"
              :id="`relation-view-action-row-${data.item.id}`"
            >
              <feather-icon
                :id="`invoice-row-${data.item.id}-preview-icon`"
                class="cursor-pointer mx-1 feather-hover"
                icon="EyeIcon"
                size="16"
              />
            </b-link>
            <b-tooltip
              :target="`relation-view-action-row-${data.item.id}`"
              placement="left"
            >
              <p class="mb-0">
                Aanpassen
              </p>
            </b-tooltip>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRelations"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BLink, BPagination, BOverlay, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import relationsStoreModule from '@/views/pages/relations/relationsStoreModule'
import useRelationsList from '@/views/pages/relations/useRelationsList'
import router from '@/router'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BLink,
    BPagination,
    vSelect,
    BOverlay,
    BTooltip,
  },
  data() {
    return {
      password: '',
    }
  },
  methods: {
    getFullName(data) {
      let fullName = ''

      if (!data.firstName) {
        data.firstName = ''
      }

      if (data) {
        if (data.middleName && data.middleName.length > 0) {
          fullName = `${data.firstName} ${data.middleName} ${data.lastName}`
        } else {
          fullName = `${data.firstName} ${data.lastName}`
        }
      }

      return fullName
    },
    goToAddRelationsView() {
      router.push({ name: 'apps-relations-sidebar' })
    },
    confirmRelationDeleteText(id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, verwijder het!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('apps-relations-list/deleteRelation', { id })
            .then(() => {
              this.refetchData()
              this.$swal({
                icon: 'success',
                title: 'Verwijderd!',
                text: 'De relatie is verwijderd.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
    documentStatusParser(status) {
      switch (status) {
        case 1:
          return 'Volledig & Gevalideerd'
        case 2:
          return 'Volledig, Wacht op validatie'
        case 3:
          return 'Niet volledig'
        default:
          return 'Onbekend'
      }
    },
    documentStatusVariantParser(status) {
      switch (status) {
        case 1:
          return 'success'
        case 2:
          return 'warning'
        case 3:
          return 'danger'
        default:
          return 'danger'
      }
    },
  },
  setup() {
    const RELATIONS_APP_STORE_MODULE_NAME = 'apps-relations-list'

    // Register module
    if (!store.hasModule(RELATIONS_APP_STORE_MODULE_NAME)) store.registerModule(RELATIONS_APP_STORE_MODULE_NAME, relationsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RELATIONS_APP_STORE_MODULE_NAME)) store.unregisterModule(RELATIONS_APP_STORE_MODULE_NAME)
    })

    const {
      fetchRelations,
      tableColumns,
      perPage,
      currentPage,
      totalRelations,
      dataMeta,
      perPageOptions,
      searchQuery,
      refRelationsListTable,
      resolveDistrictForCountry,
      refetchData,
      countryOptions,
      districtOptions,
      resolveRelationsRoleVariant,
      resolveRelationsRoleIcon,
      resolveRelationsStatusVariant,
      resolveRelationsStatusName,
      resolveRelationsRoleName,
      sortBy,
      isSortDirDesc,
      resolveTypeVariant,
      showOverlay,
    } = useRelationsList()

    return {
      fetchRelations,
      tableColumns,
      perPage,
      currentPage,
      totalRelations,
      dataMeta,
      perPageOptions,
      searchQuery,
      refRelationsListTable,
      resolveDistrictForCountry,
      refetchData,
      countryOptions,
      districtOptions,
      avatarText,
      resolveRelationsRoleVariant,
      resolveRelationsRoleIcon,
      resolveRelationsStatusVariant,
      resolveRelationsStatusName,
      resolveRelationsRoleName,
      sortBy,
      isSortDirDesc,
      resolveTypeVariant,
      showOverlay,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
